import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  MobileStepper,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";

import ActivityEditorDialog from "components/feed/ActivityEditor/ActivityEditorDialog";
import Close from "components/icons/Close";
import useMe from "hooks/useMe";
import { useOrientationTasks } from "hooks/useOrientationTasks";

import BannerCardContent from "./BannerCardContent";
import CompletedCardContent from "./CompletedCardContent";
import DismissOrientationDialog from "./DismissOrientationDialog";
import MobileDownloadDialog from "./MobileDownloadDialog";
import ProgressBar from "./ProgressBar";

export default function OrientationBanner() {
  const { name } = useMe();

  const {
    steps,
    stepKeys,
    progressStepKeys,
    isDismissedBanner,
    stepsCompleted,
    handleDismiss,
    orientationCompleted,
    hasSeenOrientationCompleted,
    mobileAppSkip,
    markStepAsCompleted,
  } = useOrientationTasks();

  const [dismissModalOpen, setDismissModalOpen] = useState(false);
  const [mobileDownloadDialogOpen, setMobileDownloadDialogOpen] =
    useState(false);
  const [activityEditorDialogOpen, setActivityEditorDialogOpen] =
    useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [bannerClosed, setBannerClosed] = useState(false);

  useEffect(() => {
    if (orientationCompleted) {
      setActiveStep(stepKeys.length - 1);
    } else {
      const firstIncompleteIndex = stepKeys.findIndex(
        (key) => !steps[key].stepCompleted
      );
      if (firstIncompleteIndex !== -1) {
        setActiveStep(firstIncompleteIndex);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orientationCompleted, stepKeys, steps]);

  const handleDismissBanner = () => {
    handleDismiss();

    if (orientationCompleted) {
      setDismissModalOpen(false);
    }
  };

  if (hasSeenOrientationCompleted || isDismissedBanner || bannerClosed) {
    return null;
  }

  return (
    <>
      <DismissOrientationDialog
        open={dismissModalOpen}
        onClose={() => setDismissModalOpen(false)}
        onConfirm={handleDismissBanner}
      />
      <MobileDownloadDialog
        open={mobileDownloadDialogOpen}
        onClose={() => setMobileDownloadDialogOpen(false)}
      />
      <ActivityEditorDialog
        open={activityEditorDialogOpen}
        onClose={() => setActivityEditorDialogOpen(false)}
      />

      <Card sx={{ backgroundColor: (theme) => theme.palette.grey[100], mb: 2 }}>
        <CardHeader
          action={
            <IconButton
              aria-label="close"
              onClick={() =>
                orientationCompleted
                  ? setBannerClosed(true)
                  : setDismissModalOpen(true)
              }
            >
              <Close fontSize="small" />
            </IconButton>
          }
          sx={{
            py: 1,
            textAlign: "center",
            "& .MuiCardHeader-action": {
              margin: "auto 0",
              alignSelf: "center",
            },
          }}
          title={
            <Typography variant="subtitle2">
              {`${name.first}, ${
                isDismissedBanner
                  ? "there's more to discover in your community!"
                  : "here's how to make the most out of your community"
              }`}
            </Typography>
          }
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            px: 3,
            pb: 2,
            width: "100%",
          }}
        >
          <ProgressBar
            maxSteps={progressStepKeys.length}
            value={stepsCompleted}
          />

          <Typography id="progress-status" variant="body2">
            {stepsCompleted}/{progressStepKeys.length} complete
          </Typography>
        </Box>

        <CardContent sx={{ p: 0, display: "grid" }}>
          {stepKeys.map((stepKey, index) => (
            <Box
              key={stepKey}
              sx={{
                gridRowStart: 1,
                gridColumnStart: 1,
                ...(index !== activeStep && { visibility: "hidden" }),
              }}
            >
              {stepKey === "completed" ? (
                <CompletedCardContent onClose={() => setBannerClosed(true)} />
              ) : (
                <BannerCardContent
                  handleButtonClick={() => {
                    if (stepKey === "download")
                      setMobileDownloadDialogOpen(true);
                    if (stepKey === "discussion")
                      setActivityEditorDialogOpen(true);
                  }}
                  handleSkip={() => {
                    markStepAsCompleted(stepKey, true);
                    mobileAppSkip();
                  }}
                  stepKey={stepKey}
                />
              )}
            </Box>
          ))}
        </CardContent>

        <MobileStepper
          activeStep={activeStep}
          backButton={
            <Button
              disabled={activeStep === 0}
              size="small"
              startIcon={<KeyboardArrowLeft />}
              onClick={() => setActiveStep((prev) => prev - 1)}
            >
              Back
            </Button>
          }
          nextButton={
            <Button
              disabled={activeStep === stepKeys.length - 1}
              endIcon={<KeyboardArrowRight />}
              size="small"
              onClick={() => setActiveStep((prev) => prev + 1)}
            >
              Next
            </Button>
          }
          position="static"
          steps={stepKeys.length}
          sx={{ backgroundColor: "transparent", p: 1, width: "100%" }}
          variant="dots"
        />
      </Card>
    </>
  );
}
