import { Box, Button, Theme, Typography, useMediaQuery } from "@mui/material";

type Props = {
  onClose: () => void;
};
export default function CompletedCardContent({ onClose }: Props) {
  const isSmDown = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  return (
    <Box
      sx={{
        backgroundColor: "primary.contrastText",
        display: "flex",
        height: "100%",
        position: "relative",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          height: "55%",
          overflow: "hidden",
          width: "100%",
        }}
      >
        <img
          alt="You’ve completed your orientation!"
          src="https://storage.googleapis.com/cco-web-assets/onboarding/banner-images/onboarding-banner-completed-compressed.jpeg"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            objectPosition: isSmDown ? "50% -25%" : "50% 35%",
            opacity: isSmDown ? 0.8 : 0.3,
            transform: isSmDown ? "scale(2)" : "scale(1)",
          }}
        />

        <Box
          sx={{
            position: "absolute",
            top: 0,
            width: "100%",
            height: "100%",
            background: `linear-gradient(0deg, #fff 1%, rgba(255, 255, 255, 0) 100%)`,
          }}
        />
      </Box>

      <Box sx={{ flex: 1, zIndex: 1 }}>
        <Box
          sx={{
            p: isSmDown ? 4 : 3,
            pt: isSmDown ? 0 : 3,
            display: "flex",
            flexDirection: "column",
            height: "100%",
            textAlign: "center",
            justifyContent: "flex-end",
          }}
        >
          <Box>
            <Typography mb={1} variant="h3">
              You’ve completed your orientation!
            </Typography>
            <Typography mb={2} variant="body2">
              You’ve completed all the starting tasks, now enjoy the community!
            </Typography>

            <Button size="medium" variant="contained" onClick={onClose}>
              Close
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
