import { CheckCircle } from "@mui/icons-material";
import { Box, Button, Theme, Typography, useMediaQuery } from "@mui/material";
import Link from "next/link";

import { useOrientationTasks } from "hooks/useOrientationTasks";

type Props = {
  stepKey: keyof ReturnType<typeof useOrientationTasks>["steps"];
  handleSkip: () => void;
  handleButtonClick: () => void;
};

export default function BannerCardContent({
  stepKey,
  handleSkip,
  handleButtonClick,
}: Props) {
  const isSmDown = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const { steps, isStepCompleted } = useOrientationTasks();
  const stepData = steps[stepKey];

  const stepCompleted = isStepCompleted(stepKey);

  return (
    <Box
      sx={{
        backgroundColor: "primary.contrastText",
        display: "flex",
        flexDirection: isSmDown ? "column-reverse" : "row",
        alignItems: "stretch",
        height: "100%",
      }}
    >
      <Box sx={{ flex: 1, mt: isSmDown ? -2 : 0, zIndex: 1 }}>
        <Box
          sx={{
            p: (theme) =>
              isSmDown ? theme.spacing(0, 3, 4) : theme.spacing(4, 4, 4, 7),
            display: "flex",
            flexDirection: "column",
            height: "100%",
            justifyContent: isSmDown ? "flex-end" : "space-between",
          }}
        >
          <Box>
            <Typography mb={1} variant="h3">
              {stepData?.title}
            </Typography>
            <Typography mb={3} variant="body2">
              {stepData?.text}
            </Typography>
          </Box>

          <Box>
            {stepData?.buttonText && !stepCompleted && (
              <Link passHref href={stepData?.link || ""}>
                <Button
                  size="medium"
                  variant="contained"
                  onClick={handleButtonClick}
                >
                  {stepData?.buttonText}
                </Button>
              </Link>
            )}
            {stepCompleted && (
              <Button
                disabled
                size="medium"
                startIcon={<CheckCircle color="action" />}
                variant="contained"
              >
                Completed
              </Button>
            )}
            {stepData?.hasSkip && !stepCompleted && (
              <Button
                size="medium"
                sx={{ ml: 3 }}
                variant="outlined"
                onClick={handleSkip}
              >
                Skip
              </Button>
            )}
          </Box>
        </Box>
      </Box>

      <Box sx={{ position: "relative", flex: isSmDown ? 0 : 1 }}>
        <img
          alt={stepData?.title}
          src={stepData?.mediaUrl}
          style={{
            width: "100%",
            height: isSmDown ? "130px" : "0",
            minHeight: "100%",
            objectFit: "cover",
          }}
        />
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: isSmDown
              ? `linear-gradient(0deg, #fff 2%, rgba(255, 255, 255, 0) 80%)`
              : `linear-gradient(90deg, #FFF 1%, rgba(255, 255, 255, 0.00) 40.39%)`,
          }}
        />
      </Box>
    </Box>
  );
}
