import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

export default function ProgressBar({ value, maxSteps }) {
  return (
    <LinearProgress
      sx={(theme) => ({
        flexGrow: 1,
        height: 20,
        borderRadius: "10px",
        [`&.${linearProgressClasses.colorPrimary}`]: {
          backgroundColor: theme.palette.action.disabledBackground,
        },
        [`& .${linearProgressClasses.bar}`]: {
          borderRadius: 5,
          backgroundColor: theme.palette.success.main,
        },
      })}
      value={(value / maxSteps) * 100}
      variant="determinate"
    />
  );
}
