import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  styled,
} from "@mui/material";

import { E164Number } from "libphonenumber-js";
import { useState } from "react";
import PhoneInput from "react-phone-number-input";

import { useApp } from "lib/common/appProvider";

const StyledPhoneInput = styled(PhoneInput)(({ theme }) => ({
  width: "100%",

  "& .PhoneInputCountry": {
    outline: `1px solid ${theme.palette.grey[600]}`,
    padding: theme.spacing(1.25, 1.5),
    borderRadius: "4px",
    "& .PhoneInputCountrySelect:focus + .PhoneInputCountryIcon + .PhoneInputCountrySelectArrow":
      {
        color: theme.palette.primary.main,
      },
  },
  "& input": {
    ...theme.typography.body1,
    width: "100%",
    border: "1px solid transparent",
    borderRadius: "4px",
    padding: "8.5px 14px",
    outline: `1px solid ${theme.palette.grey[600]}`,
    marginLeft: theme.spacing(1.5),
    height: "40px",
  },
  "& input::placeholder": {
    ...theme.typography.body1,
  },
  "&.PhoneInput--focus input, &.PhoneInput:hover input, &.PhoneInput--focus .PhoneInputCountry, &.PhoneInput:hover .PhoneInputCountry":
    {
      outlineColor: theme.palette.primary.main,
    },
  "&.PhoneInput--focus input, &.PhoneInput--focus .PhoneInputCountry": {
    outlineWidth: "2px",
  },
}));

type Props = {
  open: boolean;
  onClose: () => void;
};

export default function MobileDownloadDialog({ open, onClose }: Props) {
  const { code: brand } = useApp("site");
  const [phone, setPhone] = useState("");

  return (
    <Dialog
      PaperProps={{
        maxWidth: "100%",
      }}
      open={open}
      onClose={onClose}
    >
      <DialogTitle sx={{ p: [3, 3, 2, 3] }}>
        Download the mobile app!
      </DialogTitle>
      <DialogContent sx={{ p: [1, 3] }}>
        <Typography>
          Scan the QR code with your phone to access the App store and download
          our app or enter your mobile number and we will text you a direct
          link.
        </Typography>
        {/*  */}
        {/* TODO: Add QR CODE here and update styles! */}
        {/*  */}
        <Typography>Enter your mobile number</Typography>
        <Typography>SMS text rates may apply</Typography>
        <StyledPhoneInput
          defaultCountry="US"
          placeholder="Enter phone number"
          value={phone}
          onChange={(value?: E164Number | undefined) => setPhone(value || "")}
        />
        <Checkbox />
        <Typography>
          {`Opt-in to receive the occasional text message from ${brand}. This includes promotional texts, new event announcements, and more!`}{" "}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="contained">Send me the link</Button>
      </DialogActions>
    </Dialog>
  );
}
